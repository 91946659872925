import React from "react"
import { Card, CardBody, Row, CardTitle } from "reactstrap"
import DonutChart from "../AllCharts/DonutChart"

const MonthlyEarnings = props => {
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="h4 mb-4">Monthly Applications</CardTitle>

          <Row className="text-center mt-4">
            <div className="col-6">
              <h5 className="font-size-20">77</h5>
              <p className="text-muted">Accepted Applications</p>
            </div>
            <div className="col-6">
              <h5 className="font-size-20">0</h5>
              <p className="text-muted">Applications in Process</p>
            </div>
          </Row>
          <div dir="ltr">
            <DonutChart />
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default MonthlyEarnings
