// PaginationComponent.js
import React from "react"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { MdArrowForward, MdArrowBack } from "react-icons/md"

const PaginationComponent = ({ totalPages, currentPage, paginate }) => {
    
  const handlePageClick = page => {
    paginate(page)
  }

  const renderPaginationItems = () => {
    const items = []

    if (totalPages <= 4) {
      for (let number = 1; number <= totalPages; number++) {
        items.push(
          <PaginationItem key={number} active={number === currentPage}>
            <PaginationLink onClick={() => handlePageClick(number)}>
              {number}
            </PaginationLink>
          </PaginationItem>,
        )
      }
    } else {
      if (currentPage > 1) {
        items.push(
          <PaginationItem disabled={currentPage === 1} key="prev">
            <PaginationLink
              previous
              onClick={() => handlePageClick(currentPage - 1)}
            >
              <MdArrowBack size={15} />
            </PaginationLink>
          </PaginationItem>,
        )
      }

      // Add the first page
      items.push(
        <PaginationItem key={1} active={1 === currentPage}>
          <PaginationLink onClick={() => handlePageClick(1)}>1</PaginationLink>
        </PaginationItem>,
      )

      if (currentPage > 3) {
        items.push(
          <PaginationItem disabled key="ellipsis1">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>,
        )
      }

      // Add the range of pages around the current page
      const start = Math.max(2, currentPage - 1)
      const end = Math.min(totalPages - 1, currentPage + 1)
      for (let number = start; number <= end; number++) {
        items.push(
          <PaginationItem key={number} active={number === currentPage}>
            <PaginationLink onClick={() => handlePageClick(number)}>
              {number}
            </PaginationLink>
          </PaginationItem>,
        )
      }

      if (currentPage < totalPages - 2) {
        items.push(
          <PaginationItem disabled key="ellipsis2">
            <PaginationLink>...</PaginationLink>
          </PaginationItem>,
        )
      }

      // Add the last page
      items.push(
        <PaginationItem key={totalPages} active={totalPages === currentPage}>
          <PaginationLink onClick={() => handlePageClick(totalPages)}>
            {totalPages}
          </PaginationLink>
        </PaginationItem>,
      )

      if (currentPage < totalPages) {
        items.push(
          <PaginationItem disabled={currentPage === totalPages} key="next">
            <PaginationLink
              next
              onClick={() => handlePageClick(currentPage + 1)}
            >
              <MdArrowForward size={15} />
            </PaginationLink>
          </PaginationItem>,
        )
      }
    }

    return items
  }

  return (
    <Pagination className="pagination justify-content-end mb-0">
      {renderPaginationItems()}
    </Pagination>
  )
}

export default PaginationComponent
