import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { axiosInstance } from "api/apiConfig"
import { Alert } from "reactstrap"
import {
  AUTH_USER_LOGIN_CONSTANT,
  AUTH_USER_REGISTER_CONSTANT,
  SHUK_AUTH_PORTAL_TOKEN,
} from "redux/enums"

const INITIAL_STATE = {
  firstname: "Arham",
  middlename: "Abeer",
  lastname: "Ahmed",
  email: "arham.abeer@braincrop.net",
  dob: "04-09-1999",
  contact: "090078601",
  role: "admin",
  loading: false,
  error: false,
  apiStatus: false, // 0: no api call, 1: api call success
  response: {},
  respFrom: "",
}

export const RegisterUser = createAsyncThunk(
  AUTH_USER_REGISTER_CONSTANT,
  async data => {
    try {
      const response = await axiosInstance.post("Auth/Register", data.data)
      console.log("response: ", response)

      if (response.status === 200) {
        const _res = {
          response: response.data,
        }
        return _res
      }
      throw response?.data
    } catch (error) {
      console.log("response: ", error?.message)

      throw error?.response?.data ? error?.response?.data : error?.message
    }
  },
)

export const LoginUser = createAsyncThunk(
  AUTH_USER_LOGIN_CONSTANT,
  async data => {
    try {
      console.log("login data: ", data)

      const response = await axiosInstance.post("Auth/Login", data)
      console.log("response: ", response)

      if (response.status === 200) {
        const token = response.data.token
        localStorage.setItem(SHUK_AUTH_PORTAL_TOKEN, token)
        const _res = {
          response: response.data,
        }
        return _res
      }
      throw response.data
    } catch (error) {
      console.log("error login", error.response.data)
      throw error?.response?.data ? error?.response?.data : error
    }
  },
)

export const UserSlice = createSlice({
  initialState: INITIAL_STATE,
  name: "userSlice",
  reducers: {
    setApiResponseStutusToDefault: (state, action) => {
      return { ...state, apiStatus: false, error: false, response: {} }
    },
    setLogoutUser: (state, action) => {
      console.log("logout", action.payload)
      localStorage.removeItem(SHUK_AUTH_PORTAL_TOKEN)
      action.payload("/login")
      return { ...state, apiStatus: false, error: false, response: {} }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(LoginUser.pending, (state, action) => ({
        ...state,
        ...action.payload,
      }))
      .addCase(LoginUser.fulfilled, (state, action) => {
        console.log("login fulfilled: ", action.payload)
        state.response = action.payload.response.message
        state.respFrom = "Login"
        state.apiStatus = true
      })
      .addCase(LoginUser.rejected, (state, action) => {
        console.log("login rejected: ", action)
        state.response = action.error.message
        state.respFrom = "Login"
        state.apiStatus = true
        state.error = true
      })
    builder
      .addCase(RegisterUser.pending, (state, action) => ({}))
      .addCase(RegisterUser.fulfilled, (state, action) => {
        console.log("register fulfilled: ", action.payload)
        state.response = action.payload.response.message
        state.respFrom = "Register"
        state.apiStatus = true
      })
      .addCase(RegisterUser.rejected, (state, action) => {
        console.log("register rejected: ", action)
        state.response = action.error.message
        state.respFrom = "Register"
        state.apiStatus = true
        state.error = true
      })
  },
})

export const { setApiResponseStutusToDefault, setLogoutUser } =
  UserSlice.actions
export const userData = state => state.user
export default UserSlice.reducer
