import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Form,
  Alert,
  Input,
  FormFeedback,
} from "reactstrap"
import logoDark from "../../assets/images/logo.png"
import logoLight from "../../assets/images/logo.png"
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import PropTypes from "prop-types"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import withRouter from "components/Common/withRouter"

// actions
import { loginUser, socialLogin } from "../../store/actions"
import login from "store/auth/login/reducer"
import {
  LoginUser,
  setApiResponseStutusToDefault,
  userData,
} from "redux/slice/user/userSlice"
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums"

const Login = props => {
  document.title = "Login | Shukre Ilahi"
  const { apiStatus, response, respFrom, error } = useSelector(userData)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  //arham.abeer123421312512316@braincrop.net

  useEffect(() => {
    if (localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)) {
      navigate("/dashboard")
    }
  }, [])
  useEffect(() => {
    if (localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN)) {
      apiStatus && !error && setTimeout(() => navigate("/dashboard"), 1000)
    }
  }, [apiStatus])

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // console.log("login", values)

      const data = {
        userName: values.email,
        password: values.password,
      }
      dispatch(setApiResponseStutusToDefault())
      dispatch(LoginUser(data))
    },
  })

  // const selectLoginState = state => state.Login
  // const LoginProperties = createSelector(selectLoginState, login => ({
  //   error: login.error,
  // }))

  // const { error } = useSelector(LoginProperties)

  const signIn = type => {
    // dispatch(socialLogin(type, props.router.navigate))
  }

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type)
  }

  // console.log("validation", validation.initialValues)
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img
                        src={logoDark}
                        alt=""
                        height="160"
                        className="auth-logo-dark"
                      />
                      <img
                        src={logoLight}
                        alt=""
                        height="70"
                        className="auth-logo-light"
                      />
                    </Link>
                  </h3>

                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to Shukre Ilahi.
                    </p>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      {apiStatus && error && respFrom === "Login" ? (
                        // <AlertNotify clr="danger" msg={response} />
                        <Alert color={"danger"} style={{ marginTop: "13px" }}>
                          {response}
                        </Alert>
                      ) : apiStatus && !error && respFrom === "Login" ? (
                        // <AlertNotify clr="success" msg={response} />
                        <Alert color={"success"} style={{ marginTop: "13px" }}>
                          {response}
                        </Alert>
                      ) : null}
                      <div className="mb-3">
                        <Label htmlFor="username">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          // invalid={
                          //   validation.touched.email && validation.errors.email
                          //     ? true
                          //     : false
                          // }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {/* {validation.errors.email} */}
                          </FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          // invalid={
                          //   validation.touched.password &&
                          //   validation.errors.password
                          //     ? true
                          //     : false
                          // }
                        />
                        {/* {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null} */}
                      </div>
                      <Row className="mb-3 mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </Row>
                      <Row className="form-group mb-0">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock"></i> Forgot your password?
                        </Link>
                        <div className="col-12 mt-4 d-flex justify-content-center">
                          <Link
                            to="#"
                            className="social-list-item bg-danger text-white border-danger"
                            onClick={e => {
                              e.preventDefault()
                              socialResponse("google")
                            }}
                          >
                            <i className="mdi mdi-google" />
                          </Link>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{" "}
                  <Link to="/register" className="text-primary">
                    {" "}
                    Signup Now{" "}
                  </Link>
                </p>
                © {new Date().getFullYear()} Shukre Ilahi{" "}
                <span className="d-none d-sm-inline-block">
                  {" "}
                  - Developed with <i className="mdi mdi-heart text-danger"></i>{" "}
                  by Happy Digital.
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
