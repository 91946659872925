import React, { useEffect } from "react"
import { IoIosStats } from "react-icons/io"
import { FaShoppingBag, FaUserPlus } from "react-icons/fa"
import { MdCancel, MdDownloading } from "react-icons/md"

// import { connect } from "react-redux"
import { Row, Col } from "reactstrap"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import EmailSent from "./email-sent"
import MonthlyEarnings2 from "./montly-earnings2"
import Inbox from "./inbox"
import RecentActivity from "./recent-activity"
import WidgetUser from "./widget-user"
import YearlySales from "./yearly-sales"
import LatestTransactions from "./latest-transactions"
import LatestOrders from "./latest-orders"

//Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems } from "../../store/actions";
const reports = [
  {
    title: "New Application",
    icon: <FaShoppingBag />,
    total: "1",
    average: "+11%",
    badgecolor: "success",
  },
  {
    title: "Process Application",
    icon: <IoIosStats />,
    total: "23",
    average: "+29%",
    badgecolor: "success",
  },
  {
    title: "Accepted Application",
    icon: <FaUserPlus />,
    total: "202",
    average: "+0%",
    badgecolor: "info",
  },
  {
    title: "Hold Application",
    icon: <MdDownloading />,
    total: "1",
    average: "+89%",
    badgecolor: "warning",
  },
  {
    title: "Rejected Application",
    icon: <MdCancel />,
    total: "148",
    average: "-4%",
    badgecolor: "danger",
  },
]
const Dashboard = props => {
  document.title = "Shukre Ilahi"

  const breadcrumbItems = [
    { title: "Lexa", link: "#" },
    { title: "Dashboard", link: "#" },
  ]

  useEffect(() => {
    // props.setBreadcrumbItems('Dashboard' , breadcrumbItems)
  })

  return (
    <React.Fragment>
      {/*mimi widgets */}
      <Miniwidget reports={reports} />

      <Row>
        <Col xl="3">
          {/* Monthly Earnings */}
          <MonthlyEarnings />
        </Col>

        <Col xl="6">
          {/* Email sent */}
          <EmailSent />
        </Col>

        <Col xl="3">
          <MonthlyEarnings2 />
        </Col>
      </Row>
      <Row>
        <Col xl="4" lg="6">
          {/* inbox */}
          <Inbox />
        </Col>
        <Col xl="4" lg="6">
          {/* recent activity */}
          <RecentActivity />
        </Col>
        <Col xl="4">
          {/* widget user */}
          <WidgetUser />

          {/* yearly sales */}
          <YearlySales />
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          {/* latest transactions */}
          <LatestTransactions />
        </Col>

        <Col xl="6">
          {/* latest orders */}
          <LatestOrders />
        </Col>
      </Row>
    </React.Fragment>
  )
}

// export default connect(null, { setBreadcrumbItems })(Dashboard);
export default Dashboard
