import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap"
import { MdDelete, MdModeEdit } from "react-icons/md"
import { useEffect, useState } from "react"
import {
  DataFilter,
  ItemsPerPage,
  SearchFilter,
} from "components/Common/filters"
import { useSelector, useDispatch } from "react-redux"
import PaginationComponent from "components/Common/pagination"
import Loader from "components/Common/loader"
import { allStudents, StudentsFetch } from "redux/slice/students/studentsSlice"

export default function StudentsTable(props) {
  const dispatch = useDispatch();
  const { studentsData, loading, totalPages } = useSelector(allStudents)
  const [itemsPerPage, setItemsPerPage] = useState(5)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState("")
  const [filters, setFilters] = useState({})

  let searchedData = studentsData.filter(item =>
    Object.values(item).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase()),
    ),
  )

  const paginate = pageNumber => {
    console.log("paginate call with page number: ", pageNumber)
    setCurrentPage(pageNumber)
    let data = {
      pageNumber: pageNumber,
      pageSize: itemsPerPage,
    }
    //   dispatch(StudentsFetch(data))
  }

  useEffect(() => {
    let data = {
      pageNumber: 1,
      pageSize: itemsPerPage,
    }
    // dispatch(StudentsFetch(data))
  }, [itemsPerPage, filters])

  const handleItemsPerPage = perPage => {
    console.log("items per page", perPage);
    setItemsPerPage(Number(perPage));
  }

  const handleUpdate = received => {
    console.log("update", received);
    props.forUpdate(received);
  }

  const handleDelete = () => {
    console.log("deleting");
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody style={{ minHeight: "400px" }}>
              <CardTitle className="fs-2 dot-after">Students</CardTitle>
              <div
                className="d-flex justify-content-end flex-row align-items-center"
                style={{ width: "100%" }}
              >
                <div className="me-3">
                  <SearchFilter
                    value={searchTerm}
                    label="Search Student"
                    onChange={e => {
                      setSearchTerm(e.target.value)
                    }}
                  />
                </div>
                <div>
                  <ItemsPerPage
                    value={itemsPerPage}
                    handleItemsPerPage={handleItemsPerPage}
                  />
                </div>
              </div>

              <div className="table-responsive">
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="table mb-0 table-striped table-bordered">
                    <thead>
                      <tr>
                        {/* <th className="text-nowrap">#</th> */}
                        <th className="text-nowrap">Student Name</th>
                        <th className="text-nowrap">Email</th>
                        <th className="text-nowrap">Date Of Birth</th>
                        <th className="text-nowrap">Contact Number</th>
                        <th className="text-nowrap">Address</th>
                        <th className="text-nowrap">City</th>
                        <th className="text-nowrap">State</th>
                        <th className="text-nowrap">Country</th>
                        {/* <th className="text-nowrap">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {searchedData.map((staff, index) => (
                        <tr key={index}>
                          {/* <td className="text-nowrap">{staff.id}</td> */}
                          <td className="text-nowrap">{staff?.name}</td>
                          <td className="text-nowrap">{staff?.email}</td>
                          <td className="text-nowrap">{staff?.dateOfBirth}</td>
                          <td className="text-nowrap">
                            {staff?.contactNumber}
                          </td>
                          <td className="text-nowrap">{staff?.address}</td>
                          <td className="text-nowrap">{staff?.city}</td>
                          <td className="text-nowrap">{staff?.state}</td>
                          <td className="text-nowrap">{staff?.country}</td>
                          {/* <td className="d-flex">
                              <MdModeEdit
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleUpdate(staff)}
                                size={18}
                              />
                              <MdDelete
                                style={{ cursor: "pointer" }}
                                className="text-danger"
                                onClick={() => {}}
                                size={18}
                              />
                            </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
            <CardFooter>
              <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end align-items-center flex-wrap">
                <div style={{ marginLeft: "4px" }}>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={currentPage}
                    paginate={paginate}
                  />
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
