import { useEffect, useRef, useState } from "react"
import { FaExternalLinkAlt, FaEye, FaEyeSlash } from "react-icons/fa"
import { MdDelete } from "react-icons/md"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const InputField = ({
  label,
  compulsory,
  className,
  type,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  let regex
  let defaultValue = ""
  let errorMessage = ""
  if (type === "email") {
    // email validation regex if type = email
    regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
    errorMessage = "This is not a valid email address."
  } else if (type === "nic") {
    // cnic validation regex if type = nic
    regex = /^[0-9]{5}[0-9]{7}[0-9]$/
    type = "text"
    errorMessage = "NIC number should contain 13 digits."
  } else if (type === "tel") {
    // phone validation regex if type = tel
    regex = /^\+92\d{10}$/
    defaultValue = "+92"
    errorMessage = "Number should start with +92 and contain 10 digits onwards."
  } else {
    errorMessage = ""
  }

  const isValid = regex && value != defaultValue ? regex.test(value) : true

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      {/* <form> */}
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <input
          className={`form-control text-black ${
            !isValid && !disabled ? "is-invalid" : ""
          }`}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
      ) : (
        <p>{value}</p>
      )}
      {!isValid && !disabled && <p className="text-danger">{errorMessage}</p>}
      {/* </form> */}
    </div>
  )
}

const SelectField = ({
  label,
  compulsory,
  value,
  selectOptions,
  className,
  onChange,
  disabled,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <select
          className="form-select text-black"
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {selectOptions.map((option, index) => (
            <option key={index} option={option}>
              {option.toString()}
            </option>
          ))}
        </select>
      ) : (
        <p>{value}</p>
      )}
    </div>
  )
}

const SelectFieldForId = ({
  label,
  compulsory,
  className,
  value,
  selectOptions,
  disabled,
  onChange,
}) => {
  let valueForView = ""
  if (disabled) {
    const selectedOption = selectOptions.find(option => option.id == value)
    valueForView = selectedOption?.name
  }
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <select
          className="form-select text-black"
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {selectOptions.map((option, index) => (
            <option key={index} option={option} value={option?.id}>
              {option?.name}
            </option>
          ))}
        </select>
      ) : (
        <p>{valueForView}</p>
      )}
    </div>
  )
}

const InputNumber = ({
  label,
  compulsory,
  className,
  type,
  value,
  step,
  disabled,
  min,
  max,
  placeholder,
  onChange,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <input
          className="form-control text-black"
          step={step}
          min={min}
          max={max}
          disabled={disabled}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      ) : (
        <p>{value}</p>
      )}
    </div>
  )
}

const PhoneInputField = ({
  label,
  compulsory,
  className,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  // Define the available extensions
  const extensions = ["+91", "+92", "+94", "+84"]

  const [extension, setExtension] = useState("+92")
  const [phoneNumber, setPhoneNumber] = useState("")

  const regex = /^\d{10}$/ // Phone number validation for 10 digits

  useEffect(() => {
    if (value) {
      const ext = value.substring(0, 3); // Extract the extension (first 3 chars)
      const number = value.substring(3); // Extract the phone number (remaining chars)

      setExtension(ext);
      setPhoneNumber(number);
    }
  }, [value]);

  console.log("phone", value)
  const handleExtensionChange = e => {
    setExtension(e.target.value)
    onChange(`${e.target.value}${phoneNumber}`)
  }

  const handlePhoneNumberChange = e => {
    const value = e.target.value
    setPhoneNumber(value)
    onChange(`${extension}${value}`)
  }

  const isValid = regex.test(phoneNumber)

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <div className="input-group">
          {/* Smaller select field for country extension */}
          <select
            className="form-select text-center fw-bold"
            value={extension}
            onChange={handleExtensionChange}
            disabled={disabled}
          >
            {extensions.map(ext => (
              <option key={ext} value={ext}>
                {ext}
              </option>
            ))}
          </select>

          {/* Larger input field for phone number */}
          <input
            className={`form-control col-9 ${
              !isValid && !disabled && value?.length > 3 ? "is-invalid" : ""
            }`} // Make the input larger
            type="tel"
            style={{ width: "50%" }}
            placeholder={placeholder}
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            disabled={disabled}
            required
          />
        </div>
      ) : (
        <p>{`${extension}${phoneNumber}`}</p>
      )}
      {!isValid && !disabled && value?.length > 3 && (
        <p className="text-danger">
          Phone number should contain exactly 10 digits.
        </p>
      )}
    </div>
  )
}

const PasswordInputField = ({
  label,
  compulsory,
  className,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  const [showPassword, setShowPassword] = useState(false) // State to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <div className="input-group">
        {!disabled ? (
          <input
            className="form-control text-black"
            type={showPassword ? "text" : "password"} // Toggles between text and password
            disabled={disabled}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            required
          />
        ) : (
          <p>{value}</p>
        )}
        {/* <div className="input-group-append"> */}
        <span
          className="input-group-text btn btn-outline-secondary"
          onClick={togglePasswordVisibility}
          style={{ cursor: "pointer", height: "100%" }}
        >
          {showPassword ? <FaEyeSlash size={18} /> : <FaEye size={18} />}{" "}
          {/* Icons for visibility */}
        </span>
        {/* </div> */}
      </div>
    </div>
  )
}

export {
  InputField,
  SelectField,
  SelectFieldForId,
  InputNumber,
  PhoneInputField,
  PasswordInputField,
}
