import { axiosInstance, instanceAuth } from "api/apiConfig"

export const staffFetchAPI = async data => {
  try {
    console.log("Fetch staff in helper", data)
    const response = await instanceAuth.get("User", {
      params: {
        ...data,
      },
    })
    return response
  } catch (error) {
    console.log("error fetching staff data...", error)
    throw error
  }
}

export const staffCreateAPI = async data => {
  console.log("Create staff in helper", data)
  try {
    const response = await instanceAuth.post("User", data)
    return response
  } catch (error) {
    console.log("error creating staff...", error)
    throw error
  }
}

export const staffUpdateAPI = async ({ id: id, data: data }) => {
  try {
    console.log(`Update staff in helper with id: ${id}`, data)
    const response = await instanceAuth.put(`User/${id}`, data);
    return response
  } catch (error) {
    console.log("error updating staff...", error)
    throw error
  }
}

export const staffDeleteAPI = async id => {
  try {
    console.log(`Delete staff in helper with id: ${id}`)
    const response = await instanceAuth.delete(`User/${id}`)
    return response
  } catch (error) {
    console.log("error deleting staff...", error)
    throw error
  }
}
