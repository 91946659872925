import React, { createContext, useState } from "react"
const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const [activeStates, setActiveStates] = useState({
    main: "Dashboard",
    child: "",
  })

  return (
    <SidebarContext.Provider value={{ activeStates, setActiveStates }}>
      {children}
    </SidebarContext.Provider>
  )
}

export default SidebarContext
