import { toast } from "react-toastify"
import { GoIssueClosed } from "react-icons/go"
import { MdErrorOutline } from "react-icons/md"

export default function Notify(status, message) {
  // console.log(status, message)
  if (status === "success") {
    toast.success(message, {
      icon: <GoIssueClosed size={16} />,
      position: "top-right",
      style: {
        background: '#37A26A',
        color: "white",
        fontSize: "15px",
      },
    })
  } else {
    toast.error(message, {
      icon: <MdErrorOutline size={16} />,
      position: "top-right",
      style: {
        background: '#E67063',
        color: "white",
        fontSize: "15px",
      },
    })
  }
}
