import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import Notify from "components/Common/notify"
import { studentsFetchAPI } from "api/students/studentsApiHelper"

export const StudentsFetch = createAsyncThunk(
  "Students/StudentsFetch",
  async data => {
    try {
      const response = await studentsFetchAPI(data)
      const _response = {
        data: response?.data,
        status: response?.status,
      }
      return _response
    } catch (error) {
      Notify("error", "Failed to fetch Students Data")
      throw Error("Failed to fetch Students Data")
    }
  },
)

const initialState = {
  studentsData: [
    {
      name: "David Martinez",
      email: "david.martinez@example.com",
      dateOfBirth: "1997-11-18",
      contactNumber: "+92911234567",
      address: "50 Gran Via",
      city: "Madrid",
      state: "Madrid",
      country: "Spain",
    },
    {
      name: "Sophia Lee",
      email: "sophia.lee@example.com",
      dateOfBirth: "2000-09-30",
      contactNumber: "+941012345678",
      address: "789 Seoul Street",
      city: "Seoul",
      state: "Seoul",
      country: "South Korea",
    },
    {
      name: "Liam Brown",
      email: "liam.brown@example.com",
      dateOfBirth: "2002-06-25",
      contactNumber: "+925555555555",
      address: "321 Pine Lane",
      city: "Miami",
      state: "Florida",
      country: "USA",
    },
    {
      name: "Allen Garcia",
      email: "noah.garcia@example.com",
      dateOfBirth: "1996-08-08",
      contactNumber: "+842112345678",
      address: "25 Copacabana Avenue",
      city: "Rio de Janeiro",
      state: "Rio de Janeiro",
      country: "Brazil",
    },
  ],
  totalPages: 1,
  loading: false,
  error: null,
}

export const StudentsSlice = createSlice({
  name: "StudentsSlice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(StudentsFetch.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(StudentsFetch.fulfilled, (state, action) => {
        console.log("Students fetch payload", action.payload?.data?.items)
        state.loading = false
        state.totalPages = action.payload?.data?.totalPages
        state.studentsData = action.payload?.data?.items
      })
      .addCase(StudentsFetch.rejected, (state, action) => {
        console.log("error students fetch", action.error)
        state.loading = false
        state.error = action.error.message
      })
  },
})

export const {} = StudentsSlice.actions

export const allStudents = state => state.allStudents

export default StudentsSlice.reducer
