import { combineReducers, configureStore } from "@reduxjs/toolkit"
import allStaffs from "redux/slice/staff/staffSlice"
import allStudents from "redux/slice/students/studentsSlice"
import userSlice from "redux/slice/user/userSlice"

const rootReducer = combineReducers({
  user: userSlice,
  allStaffs: allStaffs,
  allStudents: allStudents,
})

export const store = configureStore({
  reducer: rootReducer,
})
