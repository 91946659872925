import axios from "axios"
import Notify from "components/Common/notify";
import { SHUK_AUTH_PORTAL_TOKEN } from "redux/enums";

export const axiosInstance = axios.create({
  baseURL: "https://api-staging.shukreilahi.org/api/v1/",
})

export const instanceAuth = axios.create({
  baseURL: "https://api-staging.shukreilahi.org/api/v1/",
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add an interceptor to fetch the token dynamically for every request
instanceAuth.interceptors.request.use((config) => {
  const token = localStorage.getItem(SHUK_AUTH_PORTAL_TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Add a response interceptor to handle 401 Unauthorized and redirect to login
instanceAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error code", error)
    if (error?.response?.status === 401 || error?.status === 401) {
      console.log("this is error 401 coming")
      // localStorage.removeItem(SHUK_AUTH_PORTAL_TOKEN);
      Notify("error", "Session expired. Please login again.  code: 401")
      const myTimeout = setTimeout(()=>{
        window.location.href = "/logout";
      }, 5000);
    }
    return Promise.reject(error);
  }
);