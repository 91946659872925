import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"

// import store from "./store"
import { store } from "./redux/store/index"
import { SidebarProvider } from "context/sidebar.context"

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <SidebarProvider>
    <Provider store={store}>
      <>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <App />
        </BrowserRouter>
      </>
    </Provider>
  </SidebarProvider>,
)

serviceWorker.unregister()
