export function DateFormater(dateString) {
    const date = new Date(dateString);
    const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    };

    const formatter = new Intl.DateTimeFormat("en-CA", options);
    return formatter.format(date);
}
