import React, { Component } from "react"

import { Card, Row, Col, CardBody, CardTitle } from "reactstrap"

// import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
// import { setBreadcrumbItems } from "../../store/actions"

class UiSessionTimeout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      breadcrumbItems: [
        { title: "Lexa", link: "#" },
        { title: "UI Elements", link: "#" },
        { title: "Session Timeout", link: "#" },
      ],
      timeralert: null,
      timerswitch: false,
      seconds: 0,
    }
    this.tick = this.tick.bind(this)
  }

  componentDidMount() {
    // this.props.setBreadcrumbItems("Session Timeout", this.state.breadcrumbItems);
    this.main_function()
  }

  tick() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({ seconds: prevState.seconds + 1 }))
    }, 1000)
  }

  hideAlert() {
    window.location = "/login"
  }

  confirmAlert() {
    this.setState({ timeralert: null })
  }

  main_function() {
    setTimeout(
      function () {
        setTimeout(
          function () {
            this.function1()
          }.bind(this),
          6000,
        )
        // this.function2();
      }.bind(this),
      6000,
    )
  }
  function1() {
    if (window.location.pathname === "/ui-session-timeout") {
      window.location = "/login"
    } else {
    }
  }

  render() {
    document.title = "Sessions timeout | Shukre Ilahi"

    return (
      <React.Fragment>
        {this.state.timeralert}

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>Bootstrap-session-timeout</CardTitle>
                <p className="sub-header">
                  Session timeout and keep-alive control with a nice Bootstrap
                  warning dialog.
                </p>

                <div>
                  <p>
                    After a set amount of idle time, a Bootstrap warning dialog
                    is shown to the user with the option to either log out, or
                    stay connected. If "Logout" button is selected, the page is
                    redirected to a logout URL. If "Stay Connected" is selected
                    the dialog closes and the session is kept alive. If no
                    option is selected after another set amount of idle time,
                    the page is automatically redirected to a set timeout URL.
                  </p>
                  <p>
                    Idle time is defined as no mouse, keyboard or touch event
                    activity registered by the browser.
                  </p>

                  <p className="mb-0">
                    As long as the user is active, the (optional) keep-alive URL
                    keeps getting pinged and the session stays alive. If you
                    have no need to keep the server-side session alive via the
                    keep-alive URL, you can also use this plugin as a simple
                    lock mechanism that redirects to your lock-session or
                    log-out URL after a set amount of idle time.
                  </p>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

// export default connect(null, { setBreadcrumbItems })(UiSessionTimeout)
export default UiSessionTimeout
