import React, { Component } from "react"

import C3Chart from "react-c3js"
import "c3/c3.css"

class DonutChart extends Component {
  render() {
    const data = {
      columns: [
        ["New", 115],
        ["On Hold", 3],
        ["Accepted", 77],
        ["Rejected", 35],
      ],
      type: "donut",
    }

    const donut = {
      title: "Total 115",
      width: 25,
      label: { show: !1 },
    }

    const color = {
      pattern: ["gray", "#28bbe3", "#0ca670", "#bf3939"],
    }

    const size = {
      height: 300,
    }

    return (
      <React.Fragment>
        <C3Chart
          data={data}
          donut={donut}
          color={color}
          size={size}
          dir="ltr"
        />
      </React.Fragment>
    )
  }
}

export default DonutChart
