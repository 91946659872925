import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import StudentsTable from "./students-table";

export default function Students() {
//   const [create, setCreate] = useState(false);

  return (
    <div className="">
      {/* <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          <button
            style={{
              background: "#7A6FBE",
              color: "white",
              padding: "12px 30px",
              border: "none",
              borderRadius: "10px",
              marginRight: "10px",
            }}
            onClick={toggleTab}
          >
            {create ? "View" : "Create"}
          </button>
        </Col>
      </Row> */}
      <StudentsTable />
    </div>
  );
}
