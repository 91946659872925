import { FaDonate, FaList } from "react-icons/fa"
import { MdDashboard, MdPayments } from "react-icons/md"
import { FaUserTie } from "react-icons/fa"

import { PiStudentFill } from "react-icons/pi"
import { HiOutlineDocumentReport } from "react-icons/hi"

const LEFT_ADMIN_NAVIGATION = [
  { linkName: "Dashboard", link: "/dashboard", icon: <MdDashboard /> },
  {
    linkName: "Staff Member",
    link: "/staff",
    icon: <FaUserTie />,
  },
  {
    linkName: "Application List",
    icon: <FaList />,
  },
  {
    linkName: "Donation",
    subNavs: [
      {
        linkName: "Donar Master",
        link: "/Donation/DonarMaster",
        subNavIcon: <FaDonate />,
      },
      {
        linkName: "Donations",
        /* link: '/Resources/Communities', */ subNavIcon: <FaDonate />,
      },
      {
        linkName: "Disbursements",
        /* link: '/Resources/Events', */ subNavIcon: <FaDonate />,
      },
    ],
    icon: <FaDonate />,
  },
  {
    linkName: "Report",
    subNavs: [
      {
        linkName: "Payment Schedule",
        /* link: '/Report/Payment', */ subNavIcon: <MdPayments />,
      },
    ],
    icon: <HiOutlineDocumentReport />,
  },
  {
    linkName: "Students List",
    icon: <PiStudentFill />,
    link: "/students",
  },
]

export { LEFT_ADMIN_NAVIGATION }
